import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  approveShipmentURL,
  makeRegularUserURL,
  uploadImageURL,
  uploadWaybillURL,
  getCarriersPriceURL,
  getShipmentLevelsURL,
  getRedstarCitiesByStateURL,
  getRedstarCitiesURL,
} from "../library/URLs";
import { useRef, useState, useEffect } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import * as React from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import Select from "react-select";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const styles = {
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    border: "1px solid rgba(0, 0, 0, 0.15)",
    outline: isFocused && "none",
    boxShadow: "none",
    minHeight: 42,
    height: 42,
    margin: 0,
    backgroundColor: "transparent",
    ":hover": {
      outline: "none",
      border: "1px solid rgba(0, 0, 0, 0.15)",
    },
    padding: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    border: "none",
    backgroundColor: "#fff",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    border: "none",
    color: "#000",
    fontSize: "8px",
    fontWeight: 500,
    backgroundColor: "transparent",
  }),
  input: (styles) => ({
    ...styles,
    padding: 0,
    margin: 0,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "0 8px",
  }),
};

const ApproveMultiLevelShipmentDialog = ({
  isModalVisible,
  handleCloseDialog,
  shipmentID,
  senderState,
  receiverState,
  shipmentType,
  manualApprove,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [shipmentLevels, setShipmentLevels] = useState([]);

  const [carriers, setCarriers] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [waybillUrl, setWaybillURL] = useState("");
  const [waybillLoading, setWaybillLoading] = useState(false);
  const [waybillFile, setWaybillFile] = useState();

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [fedexEconomy, setFedexEconomy] = useState(false);
  const [fedexPriority, setFedexPriority] = useState(false);
  const [fedexExpress, setFedexExpress] = useState(false);
  const [kwik, setKwik] = useState(false);
  const [dhl, setDHL] = useState(false);
  const [aramex, setAramex] = useState(false);
  const [ups, setUPS] = useState(false);
  const [redstar, setRedstar] = useState(false);
  const [anka, setAnka] = useState(false);
  const [shipToRoutely, setShipToRoutely] = useState(true);
  const [redstarCities, setRedstarCities] = useState([]);

  const [senderCities, setSenderCities] = useState([]);
  const [receiverCities, setReceiverCities] = useState([]);
  const [selectedRedstarCity, setSelectedRedstarCity] = useState({
    redstar_sender_city: "",
    redstar_receiver_city: "",
  });

  const handleRedstarSender = (val) => {
    const formvalues = {
      ...selectedRedstarCity,
      redstar_sender_city: val?.value,
      redstar_sender_city_id: redstarCities?.find(
        (city) => city?.value === val?.value
      )?.id,
    };

    setSelectedRedstarCity({
      ...formvalues,
    });
  };

  const handleRedstarReceiver = (val) => {
    console.log("af ", val);

    const formvalues = {
      ...selectedRedstarCity,
      redstar_receiver_city: val?.value,
      redstar_receiver_city_id: redstarCities?.find(
        (city) => city?.value === val?.value
      )?.id,
    };

    setSelectedRedstarCity({
      ...formvalues,
    });
  };

  const scrollToHeader = () => {
    let targetedId = document.getElementById("header");

    targetedId.scrollIntoView({ behavior: "smooth" });
  };

  const [shipmentProcessor, setShipmentProcessor] = useState("");

  const [processViaAPI, setProcessViaAPI] = useState("Yes");

  useEffect(() => {
    getRedstarCities();
    const newData = { shipment_id: shipmentID, ...UserProfileData() };
    axios
      .post(getCarriersPriceURL, newData)
      .then((response) => {
        if (response["data"]["success"]) {
          setCarriers(response["data"]["data"]["carriers"]);
        }
      })
      .catch((error) => console.log(error));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newData = { shipment_id: shipmentID, ...UserProfileData() };
    axios
      .post(getShipmentLevelsURL, newData)
      .then((response) => {
        if (response["data"]["success"]) {
          setShipmentLevels(response["data"]["data"]);
        }
      })
      .catch((error) => console.log(error));
    // eslint-disable-next-line
  }, [shipmentID]);

  const getRedstarCities = () => {
    const newData = {
      shipment_id: shipmentID,
      ...UserProfileData(),
    };

    axios
      .post(getRedstarCitiesURL, newData)
      .then(function (response) {
        console.log(response);

        if (response["data"]["success"] === true) {
          setRedstarCities(
            response["data"]["data"]?.map((city) => {
              return {
                label: city?.name,
                value: city?.name,
                id: city?.id,
              };
            })
          );
        } else {
          // setErrorMessage(response["data"]["message"]);
          // setErrorVisibility(true);
          // setTimeout(() => {
          //     setErrorVisibility(false);
          // }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  const toggleShipmentTypeCheckBox = (shipmentType) => {
    if (shipmentType === "fedexEconomy") {
      setFedexEconomy(true);
      setFedexPriority(false);
      setFedexExpress(false);
      setDHL(false);
      setUPS(false);
      setAramex(false);
      setAnka(false);
      setShipmentProcessor("Fedex-Economy");
      setProcessViaAPI("Yes");
      setKwik(false);
      setRedstar(false);
    } else if (shipmentType === "fedexPriority") {
      setFedexEconomy(false);
      setFedexPriority(true);
      setDHL(false);
      setUPS(false);
      setAramex(false);
      setAnka(false);
      setFedexExpress(false);
      setShipmentProcessor("Fedex-Priority");
      setProcessViaAPI("Yes");
      setKwik(false);
      setRedstar(false);
    } else if (shipmentType === "dhl") {
      setFedexEconomy(false);
      setFedexPriority(false);
      setDHL(true);
      setUPS(false);
      setAramex(false);
      setAnka(false);
      setFedexExpress(false);
      setShipmentProcessor("DHL");
      setProcessViaAPI("Yes");
      setKwik(false);
      setRedstar(false);
    } else if (shipmentType === "ups") {
      setFedexEconomy(false);
      setFedexPriority(false);
      setDHL(false);
      setUPS(true);
      setAramex(false);
      setAnka(false);
      setFedexExpress(false);
      setShipmentProcessor("UPS");
      setProcessViaAPI("Yes");
      setKwik(false);
      setRedstar(false);
    } else if (shipmentType === "aramex") {
      setFedexEconomy(false);
      setFedexPriority(false);
      setDHL(false);
      setUPS(false);
      setFedexExpress(false);
      setAnka(false);
      setAramex(true);
      setShipmentProcessor("Aramex");
      setProcessViaAPI("Yes");
      setKwik(false);
      setRedstar(false);
    } else if (shipmentType === "fedexPriorityExpress") {
      setFedexEconomy(false);
      setFedexExpress(true);
      setFedexPriority(false);
      setDHL(false);
      setUPS(false);
      setAnka(false);
      setAramex(false);
      setShipmentProcessor("Fedex-Priority-Express");
      setProcessViaAPI("Yes");
      setKwik(false);
      setRedstar(false);
    } else if (shipmentType === "Kwik") {
      setFedexEconomy(false);
      setFedexExpress(false);
      setFedexPriority(false);
      setDHL(false);
      setUPS(false);
      setAnka(false);
      setAramex(false);
      setShipmentProcessor("Kwik");
      setProcessViaAPI("Yes");
      setKwik(true);
      setRedstar(false);
    } else if (shipmentType === "Redstar") {
      setRedstar(true);
      setFedexPriority(false);
      setFedexExpress(false);
      setDHL(false);
      setAnka(false);
      setUPS(false);
      setAramex(false);
      setShipmentProcessor("Redstar");
      setProcessViaAPI("Yes");
      setKwik(false);
    } else if (shipmentType === "anka") {
      setRedstar(false);
      setFedexPriority(false);
      setFedexExpress(false);
      setDHL(false);
      setAnka(true);
      setUPS(false);
      setAramex(false);
      setShipmentProcessor("Anka");
      setProcessViaAPI("Yes");
      setKwik(false);
    }
  };

  const getSenderRedstarCities = () => {
    const newData = {
      shipment_id: shipmentID,
      state:
        senderState === "Federal Capital Territory"
          ? "Abuja"
          : senderState?.replace(/ State$/, ""),
      ...UserProfileData(),
    };

    axios
      .post(getRedstarCitiesByStateURL, newData)
      .then(function (response) {
        console.log(response);

        if (response["data"]["success"] === true) {
          setSenderCities(response["data"]["data"]);
        } else {
          // setErrorMessage(response["data"]["message"]);
          // setErrorVisibility(true);
          // setTimeout(() => {
          //     setErrorVisibility(false);
          // }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  const getReceiverRedstarCities = () => {
    const newData = {
      shipment_id: shipmentID,
      state:
        shipmentLevels[0]?.approved === "No"
          ? shipmentLevels[0]?.shipment_type === "Interstate"
            ? "Lagos"
            : receiverState === "Federal Capital Territory"
            ? "Abuja"
            : receiverState?.replace(/ State$/, "")
          : receiverState === "Federal Capital Territory"
          ? "Abuja"
          : receiverState?.replace(/ State$/, ""),
      ...UserProfileData(),
    };

    axios
      .post(getRedstarCitiesByStateURL, newData)
      .then(function (response) {
        console.log(response);

        if (response["data"]["success"] === true) {
          setReceiverCities(response["data"]["data"]);
        } else {
          // setErrorMessage(response["data"]["message"]);
          // setErrorVisibility(true);
          // setTimeout(() => {
          //     setErrorVisibility(false);
          // }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  const uploadWaybillDocument = (e) => {
    // const fileInput = document.querySelector('#waybill-document');
    const { files } = e.target;

    const formValues = {};
    formValues["image"] = files[0];

    setWaybillLoading(true);

    axios
      .post(uploadWaybillURL, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const waybillURL = response["data"];

        setWaybillURL(waybillURL);
        setWaybillLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const handleRedstarCity = (e) => {
    const { name, value } = e.target;

    setSelectedRedstarCity({
      ...selectedRedstarCity,
      [name]: value,
    });
  };

  const approveShipment = (waybillURL) => {
    if (waybillLoading) {
      setErrorMessage("Waybill document is uploading!");
      setErrorVisibility(true);
      scrollToHeader();

      setTimeout(() => {
        setErrorVisibility(false);
      }, 3000);
      return;
    }

    if (manualApprove) {
      if (trackingNumber === "" || waybillUrl === "") {
        setErrorMessage(
          "Ensure to provide tracking number and waybill when approving manually!"
        );
        setErrorVisibility(true);
        scrollToHeader();

        setTimeout(() => {
          setErrorVisibility(false);
        }, 5000);
        return;
      }
    }

    const formValues = {};
    formValues["shipment_id"] = shipmentID;
    formValues["shipment_type"] =
      shipmentLevels[0]?.approved === "No"
        ? shipmentLevels[0]?.shipment_type
        : shipmentLevels[1]?.approved === "No"
        ? shipmentLevels[1]?.shipment_type
        : "Import";
    formValues["shipment_processor"] = shipmentProcessor;
    formValues["via_processor_api"] = manualApprove ? "No" : "Yes";
    if (kwik) {
      if (shipToRoutely) {
        formValues["dropoff_location"] = "Routely";
      }
    }

    if (manualApprove) {
      formValues["tracking_number"] = trackingNumber;
      formValues["waybill_url"] = waybillUrl;
    }
    // if (shipmentType === "Import") {
    //     if (shipToRoutely) {
    //         formValues["dropoff_location"] = "Routely";
    //     }
    // }

    if (redstar) {
      formValues["redstar_sender_city"] =
        selectedRedstarCity.redstar_sender_city;
      formValues["redstar_receiver_city"] =
        selectedRedstarCity.redstar_receiver_city;
      formValues["redstar_sender_city_id"] =
        selectedRedstarCity.redstar_sender_city_id;
      formValues["redstar_receiver_city_id"] =
        selectedRedstarCity.redstar_receiver_city_id;
    }

    const newData = { ...formValues, ...UserProfileData() };

    console.log(newData);

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(approveShipmentURL, newData)
      .then(function (response) {
        setIsSubmitting(false);
        console.log(response);

        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Shipment has now been approved for processing");

          setTimeout(() => {
            setSuccessVisibility(false);
            handleCloseDialog("Refresh");
          }, 5000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);
          scrollToHeader();

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  const onTrackingNumberChanged = (e) => {
    setTrackingNumber(e.target.value);
  };

  // Function to find carrier by name
  function findCarrierByName(name) {
    return carriers.find((company) => company.name === name);
  }

  // Function to get percentage profit or loss
  function getPercentageDifference(costPriceStr, marginValueStr) {
    // Convert strings to float values
    const costPrice = parseFloat(costPriceStr.replace(/,/g, ""));
    const marginValue = parseFloat(marginValueStr.replace(/,/g, ""));

    let absoluteMargin = Math.abs(marginValue);

    let percentageDiff = (absoluteMargin / costPrice) * 100;
    return Math.round(percentageDiff);
  }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header" id="header">
          APPROVE MULTI-LEVEL SHIPMENT
        </h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>
          This is a multi level shipment and would require multiple approvals
        </p>
        {/* <p>This means the shipment would be processed for delivery.</p> */}
        <p>How would you like to proceed?</p>

        <div className="p-0 w-100">
          <div className="position-relative w-100 d-flex gap-2 pb-4 mb-2">
            <div
              style={{
                width: "1.5rem",
                height: "1.5rem",
                borderRadius: "100%",
                color: `${
                  shipmentLevels[0]?.approved === "No" ? "#EE2463" : "#FFFFFF"
                }`,
                border: `${
                  shipmentLevels[0]?.approved === "No"
                    ? "1px solid #EE2463"
                    : "none"
                }`,
                backgroundColor: `${
                  shipmentLevels[0]?.approved === "No" ? "#FFFFFF" : "#EE2463"
                }`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: 2,
              }}
            >
              1
            </div>
            <div
              className="position-absolute h-100"
              style={{
                width: ".5px",
                backgroundColor: "#EE2463",
                left: ".75rem",
              }}
            ></div>
            <div className="w-100" >
              <p className="mb-1">
                {shipmentLevels?.length > 0
                  ? shipmentLevels[0]?.shipment_type
                  : null}
              </p>
              {shipmentLevels[0]?.approved === "No" ? (
                shipmentLevels[0]?.shipment_type === "Interstate" ? (
                  <div className="w-100" >
                    <div>
                      <div className="d-flex gap-1 align-items-center">
                        <input
                          name="shipment_processor"
                          checked={dhl}
                          onChange={(e) => toggleShipmentTypeCheckBox("dhl")}
                          className="form-check-input"
                          type="radio"
                        />
                        <div className="d-flex w-100 justify-content-between gap-5">
                          <label
                            className="form-check-label"
                            htmlFor="allowedShipment"
                          >
                            DHL Express Premium
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 10 }}
                      className="d-flex gap-1 align-items-center"
                    >
                      <input
                        name="shipment_processor"
                        checked={redstar}
                        // disabled={shipmentLevels[0]?.approved === "No"}
                        onChange={(e) => toggleShipmentTypeCheckBox("Redstar")}
                        className="form-check-input"
                        type="radio"
                      />

                      <div className="d-flex w-100 justify-content-between">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          Redstar (Express Standard)
                        </label>
                      </div>
                    </div>
                    {redstar && (
                      <div className="row gap-3 my-3 w-100">
                      <div className="col-12">
                        <label htmlFor="" className="form-check-label">
                          Sender City
                        </label>
                        <Select
                          // className="basic-single"
                          classNamePrefix="select"
                          value={redstarCities?.find(
                            (city) =>
                              city?.value ===
                              selectedRedstarCity.redstar_sender_city
                          )}
                          // isDisabled={isDisabled}
                          onChange={handleRedstarSender}
                          // defaultValue={selectedRedstarCity.redstar_sender_city ??"Select"}
                          isClearable={false}
                          isSearchable={true}
                          styles={styles}
                          name={"redstar_sender_city"}
                          options={redstarCities ?? []}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="" className="form-check-label">
                          Receiver City
                        </label>
                        <Select
                          // className="basic-single"
                          classNamePrefix="select"
                          value={redstarCities?.find(
                            (city) =>
                              city?.value ===
                              selectedRedstarCity.redstar_receiver_city
                          )}
                          // isDisabled={isDisabled}
                          onChange={handleRedstarReceiver}
                          // defaultValue={selectedRedstarCity.redstar_receiver_city ??"Select"}
                          isClearable={false}
                          isSearchable={true}
                          styles={styles}
                          name={"redstar_receiver_city"}
                          options={redstarCities ?? []}
                        />
                      </div>
                    </div>
                    )}
                  </div>
                ) : shipmentLevels[0]?.shipment_type === "Import" ? (
                  <div>
                    <div className="w-100">
                      <div className="d-flex gap-1 w-100 align-items-center">
                        <input
                          name="shipment_processor"
                          checked={dhl}
                          onChange={(e) => toggleShipmentTypeCheckBox("dhl")}
                          className="form-check-input"
                          type="radio"
                        />
                        <div className="d-flex w-100 justify-content-between gap-5">
                          <label
                            className="form-check-label"
                            htmlFor="allowedShipment"
                          >
                            DHL Express Premium
                          </label>
                          <span className="d-flex gap-2 align-items-end">
                            <span className="percentAmt">
                              {findCarrierByName("DHL") ? (
                                <span>
                                  &#8358;{findCarrierByName("DHL")?.cost_price}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                            {findCarrierByName("DHL") ? (
                              findCarrierByName("DHL")?.margin_type ===
                              "Profit" ? (
                                <span className="percentText percentProfit">
                                  <BsArrowUp />+
                                  {getPercentageDifference(
                                    findCarrierByName("DHL")?.cost_price,
                                    findCarrierByName("DHL")?.margin_value
                                  )}
                                  %
                                </span>
                              ) : (
                                <span className="percentText percentLoss">
                                  <BsArrowDown />-
                                  {getPercentageDifference(
                                    findCarrierByName("DHL")?.cost_price,
                                    findCarrierByName("DHL")?.margin_value
                                  )}
                                  %
                                </span>
                              )
                            ) : null}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 10 }}
                      className="d-flex gap-1 align-items-center"
                    >
                      <input
                        name="shipment_processor"
                        // checked={ups}
                        disabled
                        onChange={(e) => toggleShipmentTypeCheckBox("ups")}
                        className="form-check-input"
                        type="radio"
                      />
                      <div className="d-flex w-100 justify-content-between gap-5">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          UPS Express Plus
                        </label>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 10 }}
                      className="d-flex gap-1 align-items-center"
                    >
                      <input
                        name="shipment_processor"
                        // checked={ups}
                        disabled
                        onChange={(e) => toggleShipmentTypeCheckBox("ups")}
                        className="form-check-input"
                        type="radio"
                      />
                      <div className="d-flex w-100 justify-content-between gap-5">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          UPS Express
                        </label>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 10 }}
                      className="d-flex gap-1 align-items-center"
                    >
                      <input
                        name="shipment_processor"
                        checked={ups}
                        onChange={(e) => toggleShipmentTypeCheckBox("ups")}
                        className="form-check-input"
                        type="radio"
                      />
                      <div className="d-flex w-100 justify-content-between gap-5">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          UPS Express Saver
                        </label>
                        <span className="d-flex gap-2 align-items-end">
                          <span className="percentAmt">
                            {findCarrierByName("UPS") ? (
                              <span>
                                &#8358;{findCarrierByName("UPS")?.cost_price}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                          {findCarrierByName("UPS") ? (
                            findCarrierByName("UPS")?.margin_type ===
                            "Profit" ? (
                              <span className="percentText percentProfit">
                                <BsArrowUp />+
                                {getPercentageDifference(
                                  findCarrierByName("UPS")?.cost_price,
                                  findCarrierByName("UPS")?.margin_value
                                )}
                                %
                              </span>
                            ) : (
                              <span className="percentText percentLoss">
                                <BsArrowDown />-
                                {getPercentageDifference(
                                  findCarrierByName("UPS")?.cost_price,
                                  findCarrierByName("UPS")?.margin_value
                                )}
                                %
                              </span>
                            )
                          ) : null}
                        </span>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 10 }}
                      className="d-flex gap-1 align-items-center"
                    >
                      <input
                        name="shipment_processor"
                        checked={fedexExpress}
                        onChange={(e) =>
                          toggleShipmentTypeCheckBox("fedexPriorityExpress")
                        }
                        className="form-check-input"
                        type="radio"
                      />
                      <div className="d-flex w-100 justify-content-between gap-5">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          FedEx Priority Express
                        </label>
                        <span className="d-flex gap-2 align-items-end">
                          <span className="percentAmt">
                            {findCarrierByName("FedEx Priority Express") ? (
                              <span>
                                &#8358;
                                {
                                  findCarrierByName("FedEx Priority Express")
                                    ?.cost_price
                                }
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                          {findCarrierByName("FedEx Priority Express") ? (
                            findCarrierByName("FedEx Priority Express")
                              ?.margin_type === "Profit" ? (
                              <span className="percentText percentProfit">
                                <BsArrowUp />+
                                {getPercentageDifference(
                                  findCarrierByName("FedEx Priority Express")
                                    ?.cost_price,
                                  findCarrierByName("FedEx Priority Express")
                                    ?.margin_value
                                )}
                                %
                              </span>
                            ) : (
                              <span className="percentText percentLoss">
                                <BsArrowDown />-
                                {getPercentageDifference(
                                  findCarrierByName("FedEx Priority Express")
                                    ?.cost_price,
                                  findCarrierByName("FedEx Priority Express")
                                    ?.margin_value
                                )}
                                %
                              </span>
                            )
                          ) : null}
                        </span>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 10 }}
                      className="d-flex gap-1 align-items-center"
                    >
                      <input
                        name="shipment_processor"
                        checked={fedexPriority}
                        onChange={(e) =>
                          toggleShipmentTypeCheckBox("fedexPriority")
                        }
                        className="form-check-input"
                        type="radio"
                      />
                      <div className="d-flex w-100 justify-content-between gap-5">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          Fedex Priority
                        </label>
                        <span className="d-flex gap-2 align-items-end">
                          <span className="percentAmt">
                            {findCarrierByName("FedEx Priority") ? (
                              <span>
                                &#8358;
                                {
                                  findCarrierByName("FedEx Priority")
                                    ?.cost_price
                                }
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                          {findCarrierByName("FedEx Priority") ? (
                            findCarrierByName("FedEx Priority")?.margin_type ===
                            "Profit" ? (
                              <span className="percentText percentProfit">
                                <BsArrowUp />+
                                {getPercentageDifference(
                                  findCarrierByName("FedEx Priority")
                                    ?.cost_price,
                                  findCarrierByName("FedEx Priority")
                                    ?.margin_value
                                )}
                                %
                              </span>
                            ) : (
                              <span className="percentText percentLoss">
                                <BsArrowDown />-
                                {getPercentageDifference(
                                  findCarrierByName("FedEx Priority")
                                    ?.cost_price,
                                  findCarrierByName("FedEx Priority")
                                    ?.margin_value
                                )}
                                %
                              </span>
                            )
                          ) : null}
                        </span>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 10 }}
                      className="d-flex gap-1 align-items-center"
                    >
                      <input
                        name="shipment_processor"
                        checked={fedexEconomy}
                        onChange={(e) =>
                          toggleShipmentTypeCheckBox("fedexEconomy")
                        }
                        className="form-check-input"
                        type="radio"
                      />
                      <div className="d-flex w-100 justify-content-between gap-5">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          Fedex Economy
                        </label>
                        <span className="d-flex gap-2 align-items-end">
                          <span className="percentAmt">
                            {findCarrierByName("FedEx Economy") ? (
                              <span>
                                &#8358;
                                {findCarrierByName("FedEx Economy")?.cost_price}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                          {findCarrierByName("FedEx Economy") ? (
                            findCarrierByName("FedEx Economy")?.margin_type ===
                            "Profit" ? (
                              <span className="percentText percentProfit">
                                <BsArrowUp />+
                                {getPercentageDifference(
                                  findCarrierByName("FedEx Economy")
                                    ?.cost_price,
                                  findCarrierByName("FedEx Economy")
                                    ?.margin_value
                                )}
                                %
                              </span>
                            ) : (
                              <span className="percentText percentLoss">
                                <BsArrowDown />-
                                {getPercentageDifference(
                                  findCarrierByName("FedEx Economy")
                                    ?.cost_price,
                                  findCarrierByName("FedEx Economy")
                                    ?.margin_value
                                )}
                                %
                              </span>
                            )
                          ) : null}
                        </span>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 10 }}
                      className="d-flex gap-1 align-items-center"
                    >
                      <input
                        name="shipment_processor"
                        checked={aramex}
                        onChange={(e) => toggleShipmentTypeCheckBox("aramex")}
                        className="form-check-input"
                        type="radio"
                      />

                      <div className="d-flex w-100 justify-content-between gap-5">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          Aramex
                        </label>
                        <span className="d-flex gap-2 align-items-end">
                          <span className="percentAmt">
                            {findCarrierByName("Aramex") ? (
                              <span>
                                &#8358;{findCarrierByName("Aramex")?.cost_price}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                          {findCarrierByName("Aramex") ? (
                            findCarrierByName("Aramex")?.margin_type ===
                            "Profit" ? (
                              <span className="percentText percentProfit">
                                <BsArrowUp />+
                                {getPercentageDifference(
                                  findCarrierByName("Aramex")?.cost_price,
                                  findCarrierByName("Aramex")?.margin_value
                                )}
                                %
                              </span>
                            ) : (
                              <span className="percentText percentLoss">
                                <BsArrowDown />-
                                {getPercentageDifference(
                                  findCarrierByName("Aramex")?.cost_price,
                                  findCarrierByName("Aramex")?.margin_value
                                )}
                                %
                              </span>
                            )
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className="d-flex gap-1 align-items-center">
                        <input
                          name="shipment_processor"
                          checked={dhl}
                          onChange={(e) => toggleShipmentTypeCheckBox("dhl")}
                          className="form-check-input"
                          type="radio"
                        />
                        <div className="d-flex w-100 justify-content-between gap-5">
                          <label
                            className="form-check-label"
                            htmlFor="allowedShipment"
                          >
                            DHL Express Premium
                          </label>
                          {/* <span className="d-flex gap-2 align-items-end" >
                                                <span className="percentAmt" >
                                                    {findCarrierByName("DHL") ?
                                                        <span>&#8358;{findCarrierByName("DHL")?.cost_price}</span>
                                                        : ""}
                                                </span>
                                                {
                                                    findCarrierByName("DHL") ?
                                                        findCarrierByName("DHL")?.margin_type === "Profit" ?
                                                            <span className="percentText percentProfit" >
                                                                <BsArrowUp />
                                                                +{
                                                                    getPercentageDifference(
                                                                        findCarrierByName("DHL")?.cost_price,
                                                                        findCarrierByName("DHL")?.margin_value
                                                                    )
                                                                }%
                                                            </span> :
                                                            <span className="percentText percentLoss" >
                                                                <BsArrowDown />
                                                                -{
                                                                    getPercentageDifference(
                                                                        findCarrierByName("DHL")?.cost_price,
                                                                        findCarrierByName("DHL")?.margin_value
                                                                    )
                                                                }%
                                                            </span> : null
                                                }
                                            </span> */}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 10 }}
                      className="d-flex gap-1 align-items-center"
                    >
                      <input
                        name="shipment_processor"
                        checked={kwik}
                        onChange={(e) => toggleShipmentTypeCheckBox("Kwik")}
                        className="form-check-input"
                        type="radio"
                      />
                      <div className="d-flex w-100 justify-content-between gap-5">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          Kwik
                        </label>
                      </div>
                    </div>
                    {kwik && (
                      <div>
                        <p className="mt-3 mb-1">
                          Send pickup to Routely office
                        </p>
                        <div
                          style={{ marginTop: 10 }}
                          className="d-flex gap-1 align-items-center"
                        >
                          <input
                            name="shipToRoutely"
                            checked={shipToRoutely}
                            onChange={() => setShipToRoutely(true)}
                            className="form-check-input"
                            type="radio"
                          />
                          <div className="d-flex w-100 justify-content-between gap-5">
                            <label
                              className="form-check-label"
                              htmlFor="allowedShipment"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                        <div
                          style={{ marginTop: 10 }}
                          className="d-flex gap-1 align-items-center"
                        >
                          <input
                            name="shipToRoutely"
                            checked={!shipToRoutely}
                            onChange={() => setShipToRoutely(false)}
                            className="form-check-input"
                            type="radio"
                          />
                          <div className="d-flex w-100 justify-content-between gap-5">
                            <label
                              className="form-check-label"
                              htmlFor="allowedShipment"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              ) : (
                <p className="my-0">This shipment has already been approved</p>
              )}
            </div>
          </div>
          <div className="position-relative d-flex gap-2 w-100">
            <div
              style={{
                width: "1.5rem",
                height: "1.5rem",
                borderRadius: "100%",
                color: `${
                  shipmentLevels[1]?.approved === "No" ? "#EE2463" : "#FFFFFF"
                }`,
                border: `${
                  shipmentLevels[1]?.approved === "No"
                    ? "1px solid #EE2463"
                    : "none"
                }`,
                backgroundColor: `${
                  shipmentLevels[1]?.approved === "No" ? "#FFFFFF" : "#EE2463"
                }`,
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: 2,
              }}
            >
              2
            </div>
            <div
              className="position-absolute h-100"
              style={{
                width: ".5px",
                backgroundColor: "#EE2463",
                left: ".75rem",
              }}
            ></div>
            <div>
              <p className="mb-1">{shipmentLevels[1]?.shipment_type}</p>
              {shipmentLevels[1]?.shipment_type === "Interstate" ? (
                <div>
                  <div>
                    <div className="d-flex gap-1 align-items-center">
                      <input
                        name="shipment_processor"
                        checked={
                          shipmentLevels[0]?.approved === "No" ? false : dhl
                        }
                        disabled={shipmentLevels[0]?.approved === "No"}
                        onChange={(e) => toggleShipmentTypeCheckBox("dhl")}
                        className="form-check-input"
                        type="radio"
                      />
                      <div className="d-flex w-100 justify-content-between">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          DHL Express Premium
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 10 }}
                    className="d-flex gap-1 align-items-center"
                  >
                    <input
                      name="shipment_processor"
                      checked={redstar}
                      disabled={shipmentLevels[0]?.approved === "No"}
                      onChange={(e) => toggleShipmentTypeCheckBox("Redstar")}
                      className="form-check-input"
                      type="radio"
                    />

                    <div className="d-flex w-100 justify-content-between">
                      <label
                        className="form-check-label"
                        htmlFor="allowedShipment"
                      >
                        Redstar (Express Standard)
                      </label>
                    </div>
                  </div>
                  {redstar && (
                    <div className="row gap-3 my-3">
                      <div className="col-12">
                        <label htmlFor="" className="form-check-label">
                          Sender City
                        </label>
                        <Select
                          // className="basic-single"
                          classNamePrefix="select"
                          value={redstarCities?.find(
                            (city) =>
                              city?.value ===
                              selectedRedstarCity.redstar_sender_city
                          )}
                          // isDisabled={isDisabled}
                          onChange={handleRedstarSender}
                          // defaultValue={selectedRedstarCity.redstar_sender_city ??"Select"}
                          isClearable={false}
                          isSearchable={true}
                          styles={styles}
                          name={"redstar_sender_city"}
                          options={redstarCities ?? []}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="" className="form-check-label">
                          Receiver City
                        </label>
                        <Select
                          // className="basic-single"
                          classNamePrefix="select"
                          value={redstarCities?.find(
                            (city) =>
                              city?.value ===
                              selectedRedstarCity.redstar_receiver_city
                          )}
                          // isDisabled={isDisabled}
                          onChange={handleRedstarReceiver}
                          // defaultValue={selectedRedstarCity.redstar_receiver_city ??"Select"}
                          isClearable={false}
                          isSearchable={true}
                          styles={styles}
                          name={"redstar_receiver_city"}
                          options={redstarCities ?? []}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : shipmentLevels[1]?.shipment_type === "Local" ? (
                <div>
                  <div>
                    <div className="d-flex gap-1 align-items-center">
                      <input
                        name="shipment_processor"
                        checked={dhl}
                        onChange={(e) => toggleShipmentTypeCheckBox("dhl")}
                        className="form-check-input"
                        type="radio"
                      />
                      <div className="d-flex w-100 justify-content-between gap-5">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          DHL Express Premium
                        </label>
                        {/* <span className="d-flex gap-2 align-items-end" >
                                                <span className="percentAmt" >
                                                    {findCarrierByName("DHL") ?
                                                        <span>&#8358;{findCarrierByName("DHL")?.cost_price}</span>
                                                        : ""}
                                                </span>
                                                {
                                                    findCarrierByName("DHL") ?
                                                        findCarrierByName("DHL")?.margin_type === "Profit" ?
                                                            <span className="percentText percentProfit" >
                                                                <BsArrowUp />
                                                                +{
                                                                    getPercentageDifference(
                                                                        findCarrierByName("DHL")?.cost_price,
                                                                        findCarrierByName("DHL")?.margin_value
                                                                    )
                                                                }%
                                                            </span> :
                                                            <span className="percentText percentLoss" >
                                                                <BsArrowDown />
                                                                -{
                                                                    getPercentageDifference(
                                                                        findCarrierByName("DHL")?.cost_price,
                                                                        findCarrierByName("DHL")?.margin_value
                                                                    )
                                                                }%
                                                            </span> : null
                                                }
                                            </span> */}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 10 }}
                    className="d-flex gap-1 align-items-center"
                  >
                    <input
                      name="shipment_processor"
                      checked={kwik}
                      onChange={(e) => toggleShipmentTypeCheckBox("Kwik")}
                      className="form-check-input"
                      type="radio"
                    />
                    <div className="d-flex w-100 justify-content-between gap-5">
                      <label
                        className="form-check-label"
                        htmlFor="allowedShipment"
                      >
                        Kwik
                      </label>
                    </div>
                  </div>
                  {kwik && (
                    <div>
                      <p className="mt-3 mb-1">Send pickup to Routely office</p>
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex gap-1 align-items-center"
                      >
                        <input
                          name="shipToRoutely"
                          checked={shipToRoutely}
                          onChange={() => setShipToRoutely(true)}
                          className="form-check-input"
                          type="radio"
                        />
                        <div className="d-flex w-100 justify-content-between gap-5">
                          <label
                            className="form-check-label"
                            htmlFor="allowedShipment"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex gap-1 align-items-center"
                      >
                        <input
                          name="shipToRoutely"
                          checked={!shipToRoutely}
                          onChange={() => setShipToRoutely(false)}
                          className="form-check-input"
                          type="radio"
                        />
                        <div className="d-flex w-100 justify-content-between gap-5">
                          <label
                            className="form-check-label"
                            htmlFor="allowedShipment"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="w-100">
                    <div className="d-flex gap-1 w-100 align-items-center">
                      <input
                        name="shipment_processor"
                        checked={
                          shipmentLevels[0]?.approved === "No" ? false : dhl
                        }
                        disabled={shipmentLevels[0]?.approved === "No"}
                        onChange={(e) => toggleShipmentTypeCheckBox("dhl")}
                        className="form-check-input"
                        type="radio"
                      />
                      <div className="d-flex w-100 justify-content-between gap-5">
                        <label
                          className="form-check-label"
                          htmlFor="allowedShipment"
                        >
                          DHL Express Premium
                        </label>
                        <span className="d-flex gap-2 align-items-end">
                          <span className="percentAmt">
                            {findCarrierByName("DHL") ? (
                              <span>
                                &#8358;{findCarrierByName("DHL")?.cost_price}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                          {findCarrierByName("DHL") ? (
                            findCarrierByName("DHL")?.margin_type ===
                            "Profit" ? (
                              <span className="percentText percentProfit">
                                <BsArrowUp />+
                                {getPercentageDifference(
                                  findCarrierByName("DHL")?.cost_price,
                                  findCarrierByName("DHL")?.margin_value
                                )}
                                %
                              </span>
                            ) : (
                              <span className="percentText percentLoss">
                                <BsArrowDown />-
                                {getPercentageDifference(
                                  findCarrierByName("DHL")?.cost_price,
                                  findCarrierByName("DHL")?.margin_value
                                )}
                                %
                              </span>
                            )
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 10 }}
                    className="d-flex gap-1 align-items-center"
                  >
                    <input
                      name="shipment_processor"
                      // checked={ups}
                      disabled
                      onChange={(e) => toggleShipmentTypeCheckBox("ups")}
                      className="form-check-input"
                      type="radio"
                    />
                    <div className="d-flex w-100 justify-content-between gap-5">
                      <label
                        className="form-check-label"
                        htmlFor="allowedShipment"
                      >
                        UPS Express Plus
                      </label>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 10 }}
                    className="d-flex gap-1 align-items-center"
                  >
                    <input
                      name="shipment_processor"
                      // checked={ups}
                      disabled
                      onChange={(e) => toggleShipmentTypeCheckBox("ups")}
                      className="form-check-input"
                      type="radio"
                    />
                    <div className="d-flex w-100 justify-content-between gap-5">
                      <label
                        className="form-check-label"
                        htmlFor="allowedShipment"
                      >
                        UPS Express
                      </label>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 10 }}
                    className="d-flex gap-1 align-items-center"
                  >
                    <input
                      name="shipment_processor"
                      checked={ups}
                      disabled={shipmentLevels[0]?.approved === "No"}
                      onChange={(e) => toggleShipmentTypeCheckBox("ups")}
                      className="form-check-input"
                      type="radio"
                    />
                    <div className="d-flex w-100 justify-content-between gap-5">
                      <label
                        className="form-check-label"
                        htmlFor="allowedShipment"
                      >
                        UPS Express Saver
                      </label>
                      <span className="d-flex gap-2 align-items-end">
                        <span className="percentAmt">
                          {findCarrierByName("UPS") ? (
                            <span>
                              &#8358;{findCarrierByName("UPS")?.cost_price}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        {findCarrierByName("UPS") ? (
                          findCarrierByName("UPS")?.margin_type === "Profit" ? (
                            <span className="percentText percentProfit">
                              <BsArrowUp />+
                              {getPercentageDifference(
                                findCarrierByName("UPS")?.cost_price,
                                findCarrierByName("UPS")?.margin_value
                              )}
                              %
                            </span>
                          ) : (
                            <span className="percentText percentLoss">
                              <BsArrowDown />-
                              {getPercentageDifference(
                                findCarrierByName("UPS")?.cost_price,
                                findCarrierByName("UPS")?.margin_value
                              )}
                              %
                            </span>
                          )
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 10 }}
                    className="d-flex gap-1 align-items-center"
                  >
                    <input
                      name="shipment_processor"
                      checked={fedexExpress}
                      disabled={shipmentLevels[0]?.approved === "No"}
                      onChange={(e) =>
                        toggleShipmentTypeCheckBox("fedexPriorityExpress")
                      }
                      className="form-check-input"
                      type="radio"
                    />
                    <div className="d-flex w-100 justify-content-between gap-5">
                      <label
                        className="form-check-label"
                        htmlFor="allowedShipment"
                      >
                        FedEx Priority Express
                      </label>
                      <span className="d-flex gap-2 align-items-end">
                        <span className="percentAmt">
                          {findCarrierByName("FedEx Priority Express") ? (
                            <span>
                              &#8358;
                              {
                                findCarrierByName("FedEx Priority Express")
                                  ?.cost_price
                              }
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        {findCarrierByName("FedEx Priority Express") ? (
                          findCarrierByName("FedEx Priority Express")
                            ?.margin_type === "Profit" ? (
                            <span className="percentText percentProfit">
                              <BsArrowUp />+
                              {getPercentageDifference(
                                findCarrierByName("FedEx Priority Express")
                                  ?.cost_price,
                                findCarrierByName("FedEx Priority Express")
                                  ?.margin_value
                              )}
                              %
                            </span>
                          ) : (
                            <span className="percentText percentLoss">
                              <BsArrowDown />-
                              {getPercentageDifference(
                                findCarrierByName("FedEx Priority Express")
                                  ?.cost_price,
                                findCarrierByName("FedEx Priority Express")
                                  ?.margin_value
                              )}
                              %
                            </span>
                          )
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 10 }}
                    className="d-flex gap-1 align-items-center"
                  >
                    <input
                      name="shipment_processor"
                      checked={fedexPriority}
                      disabled={shipmentLevels[0]?.approved === "No"}
                      onChange={(e) =>
                        toggleShipmentTypeCheckBox("fedexPriority")
                      }
                      className="form-check-input"
                      type="radio"
                    />
                    <div className="d-flex w-100 justify-content-between gap-5">
                      <label
                        className="form-check-label"
                        htmlFor="allowedShipment"
                      >
                        Fedex Priority
                      </label>
                      <span className="d-flex gap-2 align-items-end">
                        <span className="percentAmt">
                          {findCarrierByName("FedEx Priority") ? (
                            <span>
                              &#8358;
                              {findCarrierByName("FedEx Priority")?.cost_price}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        {findCarrierByName("FedEx Priority") ? (
                          findCarrierByName("FedEx Priority")?.margin_type ===
                          "Profit" ? (
                            <span className="percentText percentProfit">
                              <BsArrowUp />+
                              {getPercentageDifference(
                                findCarrierByName("FedEx Priority")?.cost_price,
                                findCarrierByName("FedEx Priority")
                                  ?.margin_value
                              )}
                              %
                            </span>
                          ) : (
                            <span className="percentText percentLoss">
                              <BsArrowDown />-
                              {getPercentageDifference(
                                findCarrierByName("FedEx Priority")?.cost_price,
                                findCarrierByName("FedEx Priority")
                                  ?.margin_value
                              )}
                              %
                            </span>
                          )
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 10 }}
                    className="d-flex gap-1 align-items-center"
                  >
                    <input
                      name="shipment_processor"
                      checked={fedexEconomy}
                      disabled={shipmentLevels[0]?.approved === "No"}
                      onChange={(e) =>
                        toggleShipmentTypeCheckBox("fedexEconomy")
                      }
                      className="form-check-input"
                      type="radio"
                    />
                    <div className="d-flex w-100 justify-content-between gap-5">
                      <label
                        className="form-check-label"
                        htmlFor="allowedShipment"
                      >
                        Fedex Economy
                      </label>
                      <span className="d-flex gap-2 align-items-end">
                        <span className="percentAmt">
                          {findCarrierByName("FedEx Economy") ? (
                            <span>
                              &#8358;
                              {findCarrierByName("FedEx Economy")?.cost_price}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        {findCarrierByName("FedEx Economy") ? (
                          findCarrierByName("FedEx Economy")?.margin_type ===
                          "Profit" ? (
                            <span className="percentText percentProfit">
                              <BsArrowUp />+
                              {getPercentageDifference(
                                findCarrierByName("FedEx Economy")?.cost_price,
                                findCarrierByName("FedEx Economy")?.margin_value
                              )}
                              %
                            </span>
                          ) : (
                            <span className="percentText percentLoss">
                              <BsArrowDown />-
                              {getPercentageDifference(
                                findCarrierByName("FedEx Economy")?.cost_price,
                                findCarrierByName("FedEx Economy")?.margin_value
                              )}
                              %
                            </span>
                          )
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 10 }}
                    className="d-flex gap-1 align-items-center"
                  >
                    <input
                      name="shipment_processor"
                      checked={aramex}
                      disabled={shipmentLevels[0]?.approved === "No"}
                      onChange={(e) => toggleShipmentTypeCheckBox("aramex")}
                      className="form-check-input"
                      type="radio"
                    />

                    <div className="d-flex w-100 justify-content-between gap-5">
                      <label
                        className="form-check-label"
                        htmlFor="allowedShipment"
                      >
                        Aramex
                      </label>
                      <span className="d-flex gap-2 align-items-end">
                        <span className="percentAmt">
                          {findCarrierByName("Aramex") ? (
                            <span>
                              &#8358;{findCarrierByName("Aramex")?.cost_price}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        {findCarrierByName("Aramex") ? (
                          findCarrierByName("Aramex")?.margin_type ===
                          "Profit" ? (
                            <span className="percentText percentProfit">
                              <BsArrowUp />+
                              {getPercentageDifference(
                                findCarrierByName("Aramex")?.cost_price,
                                findCarrierByName("Aramex")?.margin_value
                              )}
                              %
                            </span>
                          ) : (
                            <span className="percentText percentLoss">
                              <BsArrowDown />-
                              {getPercentageDifference(
                                findCarrierByName("Aramex")?.cost_price,
                                findCarrierByName("Aramex")?.margin_value
                              )}
                              %
                            </span>
                          )
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 10 }}
                    className="d-flex gap-1 align-items-center"
                  >
                    <input
                      name="shipment_processor"
                      checked={anka}
                      onChange={(e) => toggleShipmentTypeCheckBox("anka")}
                      className="form-check-input"
                      type="radio"
                    />
                    <div className="d-flex w-100 justify-content-between">
                      <label
                        className="form-check-label"
                        htmlFor="allowedShipment"
                      >
                        Anka
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {manualApprove && (
            <div
              style={{ marginTop: 10 }}
              className="d-flex gap-1 align-items-center"
            >
              <div className="row gap-3 my-3">
                <div className="col-12">
                  <label htmlFor="" className="form-check-label">
                    Enter Tracking Number
                  </label>
                  <input
                    type="tel"
                    name="tracking_number"
                    value={trackingNumber}
                    onChange={(e) => setTrackingNumber(e.target.value)}
                    id="tracking_number"
                    className="form-control"
                  />
                </div>
                <div className="col-12">
                  <div className="d-flex gap-2 align-items-center">
                    <label htmlFor="" className="form-check-label">
                      Upload Waybill Document
                    </label>
                    {waybillLoading && (
                      <span className="spinner-border spinner-grow-sm" />
                    )}
                  </div>
                  <input
                    type="file"
                    name="waybill"
                    value={waybillFile}
                    onChange={uploadWaybillDocument}
                    id="waybill"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>

        <SubmitButton
          onClick={(e) => approveShipment("")}
          type="button"
          className="confirm-button"
        >
          {isSubmitting === false ? (
            <span className="button-text">APPROVE SHIPMENT</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveMultiLevelShipmentDialog;
